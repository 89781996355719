// extracted by mini-css-extract-plugin
export var arrowForward = "cardSection-module--arrowForward--618c7";
export var arrowForward1 = "cardSection-module--arrowForward1--72082";
export var arrowForward2 = "cardSection-module--arrowForward2--5183a";
export var card = "cardSection-module--card--5ef7d";
export var cardContent = "cardSection-module--cardContent--f075f";
export var cardsContainer = "cardSection-module--cardsContainer--70a6f";
export var fitCards = "cardSection-module--fitCards--d91e7";
export var heading = "cardSection-module--heading--94ce0";
export var icon = "cardSection-module--icon--ba7c6";
export var linkContainer = "cardSection-module--linkContainer--95c23";
export var mobileColumns = "cardSection-module--mobileColumns--1a812";
export var noIcon = "cardSection-module--noIcon--3ecab";
export var singleRowIconTitle = "cardSection-module--singleRowIconTitle--0221c";
export var stacked = "cardSection-module--stacked--8467c";
export var strech = "cardSection-module--strech--72753";
export var stretch = "cardSection-module--stretch--b603c";
export var subtitle = "cardSection-module--subtitle--02801";
export var wrapper = "cardSection-module--wrapper--f8422";
import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import CardSection from '@components/index-components/cardSection';
import Contact from '@components/contact';
import Hero from '../components/hero';
import Layout from '@components/layout';

const AllmannaVillkor = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
    },
  } = data;
  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader;

  const products = data.contentfulPage.pageContent[3].card;

  return (
    <Layout>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[{ path: '/', name: 'Hem' }]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
        extraMarkdown={pageHeaderSubtitle}
      />
      <CardSection products={products} titlesOnly mobileColumns />
      <Contact headerText={contactHeader} isLargeContact={true} hideFAQ />
    </Layout>
  );
};

export default AllmannaVillkor;

AllmannaVillkor.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query AllmannaVillkorQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCardsSectionComponent {
          card {
            title
            linkText
            linkPath
            additionalLinkText
            additionalLinkPath
            description
            icons {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

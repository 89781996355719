import React from 'react';
import propTypes from 'prop-types';

import { Link } from 'gatsby-plugin-intl';
import * as styles from './cardSection.module.css';
import ArrowRight from '../../icons/arrow_right_main.svg';
import ExternalLink from '../../icons/ExternalLink.svg';
import ScrollableContainer from '../scrollableContainer';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import ReactMarkdown from 'react-markdown';

const CardSection = ({
  products,
  titlesOnly,
  mobileColumns,
  fitCards,
  stacked,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 896px)' });

  const wrapperClasses = classNames({
    [styles.wrapper]: true,
    [styles.mobileColumns]: mobileColumns,
    [styles.fitCards]: fitCards,
    [styles.stacked]: stacked,
    [styles.stretch]: fitCards && mobileColumns,
  });

  const LinkButton = (path, text) => {
    if (path.startsWith('http') || path.endsWith('.pdf')) {
      return (
        <a href={path} target="_blank" rel="noreferrer noopener">
          {text}
          <ExternalLink alt="Extern länk" />
        </a>
      );
    }

    return (
      <Link to={path}>
        {text}
        <ArrowRight alt="Arrow pointing forwards" />
      </Link>
    );
  };

  const content = (
    <div className={`${styles.cardsContainer}`}>
      {products.map(product => {
        const iconDesktop =
          product.icons != null && product.icons.length > 0
            ? product.icons[0].file.url
            : product.icon != null
            ? product.icon.file.url
            : null;

        const cardClasses = [styles.card];
        if (!iconDesktop) {
          cardClasses.push(styles.noIcon);
        }

        return (
          <div
            className={cardClasses.join(' ')}
            key={product.title || product.subtitle}
          >
            <div className={titlesOnly ? styles.singleRowIconTitle : ''}>
              <div className={styles.icon}>
                {iconDesktop != null && (
                  <img src={iconDesktop} height={68} alt="Card icon" />
                )}
              </div>
              <div className={styles.heading}>
                <div>
                  <h2>{product.title}</h2>
                  {product.subtitle && (
                    <div className={styles.subtitle}>{product.subtitle}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.cardContent}>
              {product.description && (
                <ReactMarkdown>{product.description}</ReactMarkdown>
              )}
              {product.linkPath &&
                LinkButton(product.linkPath, product.linkText)}
              {product.additionalLinkPath &&
                LinkButton(
                  product.additionalLinkPath,
                  product.additionalLinkText,
                )}
            </div>
          </div>
        );
      })}
    </div>
  );

  /*
  if (!isMobile && fitCards) {
    return (
      <div className={wrapperClasses}>{content}</div>
    )
  }
  */
  if (stacked) {
    return <div className={wrapperClasses}>{content}</div>;
  }

  if (mobileColumns && fitCards) {
    return (
      <div className="container">
        <div className={wrapperClasses}>{content}</div>
      </div>
    );
  }

  if (!mobileColumns || !isMobile) {
    return (
      <div className={wrapperClasses}>
        <ScrollableContainer showButtons>{content}</ScrollableContainer>
      </div>
    );
  }

  return <div className={wrapperClasses}>{content}</div>;
};

export default CardSection;

CardSection.propTypes = {
  products: propTypes.arrayOf(propTypes.object),
  titlesOnly: propTypes.bool,
  mobileColumns: propTypes.bool,
  fitCards: propTypes.bool,
  stacked: propTypes.bool,
};

CardSection.defaultProps = {};
